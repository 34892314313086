import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from './constans'
import { ITariff, IUser } from './types'
// import { localStorageKeys } from './localStorageKeys'

type Link = {
  tariff_id?: number
  is_partner?: boolean
  is_subscribe?: boolean
  phone?: string
  sbp?: number | string
  for_two?: 0 | 1
  code?: string
  Authentication?: any
  Bearer?: any
}

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    // prepareHeaders: async (headers, { endpoint }) => {
    //   const localToken =
    //   localStorage.getItem('access_token') &&
    //     JSON.parse(localStorage.getItem('access_token') as string)
    //   const user = localToken

    //   // console.log('user', user)

    //   if (user) {
    //     headers.set('Authorization', `Bearer ${user.token}`); // Исправлено
    //   }
    //   return headers
    // },
  }),
  tagTypes: ['tariff', 'paymentLink', 'userCode', 'Authentication', 'payment'],
  endpoints: (builder) => ({
    getTariffsList: builder.query<{ tariffs: ITariff[] }, string>({
      query() {
        return {
          url: 'api/tariffs',//
          method: 'GET',
          // params: {
          //   is_subscribe,
          // },
        }
      },
      providesTags: ['tariff'],
    }),

    getPaymentLink: builder.query<any, Link>({
      query({ tariff_id, is_partner, is_subscribe, phone, for_two }) {
        return {
          url: 'api/registration/send-code', //api/registration/confirm-code
          params: {
            tariff_id: tariff_id,
            is_partner,
            is_subscribe,
            phone,
            sbp: 0,
            for_two,
          },
          method: 'GET',
        }
      },
      providesTags: ['paymentLink'],
    }),
    
  //   createYourEndPoint: builder.mutation({
  //     query: (body) => ({
  //       url: `youEndPoint`,
  //       method: "POST",
  //       body,
  //       prepareHeaders: (headers) => {
  //         headers.set('Authorization', `Bearer ${user.token}`);
  //           return headers
  //       },
  //     }),
  //     invalidatesTags: ["YourEndPointTag"],
  // }),
    postUserCode: builder.mutation<any, Link>({
      query({ phone, code }) {
        return {
          url: 'api/registration/confirm-code',//'?phone=79828905745&code=8714'
          params: {
            phone,
            code,
          },
          method: 'POST',
        }
      },
      invalidatesTags: ['userCode'],
    }),
    
    getDataUser: builder.query<{ profile: IUser[] }, string>({
      query(token) {
        // console.log(token);
        return {
          url: 'api/profile', 
          method: 'GET',
          headers: { Authorization: `Bearer ${token}`,},
        }
      },
      providesTags: ['Authentication'],
    }),

    getPaymentUser: builder.query<any, Link>({
      query({ phone, tariff_id }) {
        console.log(tariff_id, phone);
        return {
          url: 'api/payment', 
          method: 'GET',
          params: {
            tariff_id,
            is_partner: 0,
            sbp: 0,
            phone,
          },
        }
      },
      providesTags: ['payment'],
    }),
  }),
})

export const {useGetPaymentUserQuery, useGetTariffsListQuery, useLazyGetPaymentLinkQuery, useGetDataUserQuery, usePostUserCodeMutation } = userApi
