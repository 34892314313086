import styles from './styles.module.scss'
// import user from '../../../assets/images/UserFace.png'
import { FC, useState, useEffect } from 'react'
import InputPhoneForm from '@atoms/InputPhone'
import { useInput } from '@hooks/useInput'
import ModalSuccess from '@atoms/ModalSuccess'
import ModalAttention from '@atoms/ModalAttention'
import ModalTariff from '@atoms/ModalTariff'
import { useGetPaymentUserQuery } from '@services/user'
import { useSearchParams, useNavigate } from 'react-router-dom'

interface Props {
  profileUser: any
}

const UserCard: FC<Props> = ({ profileUser }) => {
  const [stepNext, setStepNext] = useState(0) //22 button Купить, 2 успех, 4 неудача
  const [tariff_id, setTariff_id] = useState(0)
  const [token, setToken] = useState(false)
  const { data: link } = useGetPaymentUserQuery(
    { phone: profileUser.phone, tariff_id: tariff_id },
    { skip: !token }
  )
  const [params] = useSearchParams()
  const navigate = useNavigate()

  const tariff = () => {
    setStepNext(22)
  }
  const success = () => {
    setStepNext(2)
  }
  const warning = () => {
    setStepNext(4)
  }

  useEffect(() => {
    const paymentStatus = params.get('payment_status')
    // console.log(params.get('payment_status'))
    if (paymentStatus) {
      if (paymentStatus === 'success') {
        success()
      } else if (paymentStatus === 'rejected') {
        warning()
      }
      navigate(window.location.pathname, { replace: true })
    }
  }, [params, navigate])

  useEffect(() => {
    if (tariff_id) {
      setToken(true)
      // console.log('RTQ GET', token, tariff_id)
    }
  }, [tariff_id, token])

  useEffect(() => {
    // console.log('payment link', link)
    if (!link) {
      return
    }
    if (typeof link === 'string') {
      const win: Window = window
      win.location.href = link
    } else if (!link.success) {
      console.log('error')
    } else if (link.success) {
      console.log('success')
    }
  }, [link])

  const formatDate = (dateString: string | null): string => {
    if (!dateString) return 'Не приобретена'
    const date = new Date(dateString)
    const day = date.getDate()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    return `${day}.${month}.${year}`
  }

  const formattedDate = formatDate(profileUser.subscribe_end_date)

  const {
    bind: bindPhone,
    // setValue,
    // value: phoneUser,
  } = useInput(profileUser.phone || '')

  const redirectToGooglePlay = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=com.sayes&hl=ru',
      '_blank'
    )
  }

  return (
    <div className={styles.placeForCards}>
      {stepNext === 4 && <ModalAttention setStepNext={setStepNext} />}
      {stepNext === 2 && <ModalSuccess setStepNext={setStepNext} />}
      {stepNext === 22 && (
        <ModalTariff
          setStepNext={setStepNext}
          subscribe_end_date={profileUser.subscribe_end_date}
          setTariff_id={setTariff_id}
        />
      )}
      <div className={styles.userCard}>
        {profileUser.avatar === null ? (
          '&nbsp;' // <img src={user} alt="user avatar" width={122} height={122} />
        ) : (
          <div className={styles.avatarWrapper}>
            <img
              src={profileUser.avatar}
              alt="user avatar"
              width={122}
              height={122}
            />
          </div>
        )}
        <div className={styles.infoBlock}>
          <p className={styles.nameField}>Никнейм </p>
          <p className={styles.textInBorder}>{profileUser.nickname}</p>
        </div>
        <div className={styles.infoBlock}>
          <p className={styles.nameField}>Номер телефона</p>
          <InputPhoneForm
            required
            disabled
            {...bindPhone}
            className={styles.phone}
          />
        </div>
        <div className={styles.infoBlock}>
          <p className={styles.nameField}>Пол</p>
          <p className={styles.textInBorder2}>
            {profileUser.gender === 0 ? 'Женский' : ''}
            {profileUser.gender === 1 ? 'Мужской' : ''}
          </p>
        </div>
      </div>
      <div className={styles.modalContent}>
        <div className={styles.status}>
          <div className={styles.wrapperActive}>
            <p className={styles.semiBold600}>Подписка</p>
            {!profileUser.subscribe_active && (
              <p className={styles.noActive}>не активна</p>
            )}
            {profileUser.subscribe_active && (
              <p className={styles.active}>активна</p>
            )}
          </div>
          {profileUser.subscribe_end_date === null ? (
            <p className={styles.regular400}>Не приобретена</p>
          ) : (
            <p className={styles.regular400}>
              Действует до &nbsp;{formattedDate}
            </p>
          )}
        </div>
        <button className={styles.closeButton} onClick={tariff}>
          {profileUser.subscribe_active
            ? 'Продлить для себя и партнера'
            : 'Купить для себя и партнера'}
        </button>
      </div>
      <div className={styles.contentStore}>
        <p className={styles.semiBold600Store}>Доступные магазины приложений</p>
        <p className={styles.regular400Store}>
          Вы можете скачать приложение в одном из магазинов
        </p>
        <button className={styles.AppGallery}> </button>
        <button className={styles.GooglePlay} onClick={redirectToGooglePlay}>
          {' '}
        </button>
        <button className={styles.AppStore}> </button>
      </div>
    </div>
  )
}

export default UserCard
