import styles from './styles.module.scss'
import cn from 'classnames'
import { FC, useState, useEffect } from 'react'
import { useGetTariffsListQuery } from '@services/user'

interface Props {
  subscribe_end_date: string
  setStepNext: React.Dispatch<React.SetStateAction<number>>
  setTariff_id: React.Dispatch<React.SetStateAction<number>>
}

const ModalTariff: FC<Props> = ({
  setStepNext,
  subscribe_end_date,
  setTariff_id,
}) => {
  const [selectedTariff, setSelectedTariff] = useState<number | null>(null)
  const [newEndDate, setNewEndDate] = useState<string>('')
  const { data: tariffs } = useGetTariffsListQuery('1')
  const [tariffId, setTariffId] = useState<any>([])
  useEffect(() => {
    if (tariffs) {
      setTariffId(tariffs?.tariffs)
      // setTariffId((prevTariffId: any) => [
      //   ...prevTariffId,
      //   ...tariffs.tariffs,
      //   ...tariffs.tariffs,
      // ])
    }
  }, [tariffs])

  const addDays = (date: Date, days: number) => {
    const newDate = new Date(date)
    newDate.setDate(newDate.getDate() + days)
    return newDate
  }

  const handleTariffSelect = (index: number, days: number) => {
    setSelectedTariff(index)

    const subscribeEndDate: string = subscribe_end_date
      ? subscribe_end_date
      : new Date().toString()
    // console.log('.toDateString()', index, days, subscribeEndDate)

    const endDate = new Date(subscribeEndDate)
    const newEndDate = addDays(endDate, days)
    const formattedDate = `${newEndDate.getDate()}.${String(
      newEndDate.getMonth() + 1
    ).padStart(2, '0')}.${newEndDate.getFullYear()}`

    setNewEndDate(formattedDate)
  }

  const handlePayment = () => {
    // console.log('tariff in modal', selectedTariff)
    if (selectedTariff) {
      setTariff_id(selectedTariff)
    }
    onClose()
  }

  const onClose = () => {
    setStepNext(0)
  }

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalTop}>
          <button className={styles.close_button} onClick={onClose}>
            &times;
          </button>
          <h2 className={styles.semiBold600}>Тарифы</h2>
        </div>
        <div className={styles.tariff_cards}>
          {tariffId.map((tariff: any) => (
            <div
              key={tariff.id}
              className={cn(styles.tariff_card, {
                [styles.selected]: selectedTariff === tariff.id,
              })}
              onClick={() => handleTariffSelect(tariff.id, tariff.amount_days)}
            >
              <div className={styles.first_string}>
                <div className={styles.tariff_months}>{tariff.name}</div>
                <div
                  className={cn(styles.select_circle, {
                    [styles.active]: selectedTariff === tariff.id,
                  })}
                >
                  {selectedTariff === tariff.id && (
                    <span className={styles.checkmark}>&#10003;</span>
                  )}
                </div>
              </div>
              <div className={styles.tariff_days}>
                {tariff.amount_days} дней
              </div>
              <div className={styles.tariff_price}>
                {tariff.price} ₽{' '}
                <span className={styles.tariff_price2}>
                  {tariff.discount_price} ₽{' '}
                </span>
              </div>
            </div>
          ))}
        </div>
        {selectedTariff !== null ? (
          <p className={styles.regular400}>Будет действовать до {newEndDate}</p>
        ) : (
          <p className={styles.regular400}>Выберите тариф</p>
        )}
        <button
          className={cn(styles.payment_button, {
            [styles.active]: selectedTariff !== null,
          })}
          onClick={handlePayment}
          disabled={selectedTariff === null}
        >
          Оплатить
        </button>
      </div>
    </div>
  )
}

export default ModalTariff
