import styles from './styles.module.scss'
import logo from '../../../assets/images/StarForModal.png'
import { FC } from 'react'

interface Props {
  setStepNext: React.Dispatch<React.SetStateAction<number>>
}

const ModalSuccess: FC<Props> = ({ setStepNext }) => {
  const onClose = () => {
    setStepNext(1)
  }

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <img src={logo} alt="gold star icon" width={100} height={100} />
        <p className={styles.semiBold600}>
          Подписка успешно <br></br> оплачена!
        </p>
        <p className={styles.regular400}>
          Вы и ваш партнер теперь могут пользоваться приложением
        </p>
        <button className={styles.closeButton} onClick={onClose}>
          Закрыть
        </button>
      </div>
    </div>
  )
}

export default ModalSuccess
