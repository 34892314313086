import styles from './styles.module.scss'
import logo from '../../../assets/images/WarningImgModal.png'
import { FC } from 'react'

interface Props {
  setStepNext: React.Dispatch<React.SetStateAction<number>>
}

const ModalAttention: FC<Props> = ({ setStepNext }) => {
  const onClose = () => {
    setStepNext(0)
  }

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <img
          src={logo}
          alt="sign to attract attention"
          width={100}
          height={100}
        />
        <p className={styles.semiBold600}>Что то пошло не так</p>
        <p className={styles.regular400}>
          Оплата не была проведена. <br></br> Попробуйте еще раз, или <br></br>{' '}
          поменяется способ оплаты
        </p>
        <button className={styles.closeButton} onClick={onClose}>
          Закрыть
        </button>
      </div>
    </div>
  )
}

export default ModalAttention
