import Card from '@organisms/Card'
import styles from './styles.module.scss'
import Button from '@atoms/Button'
import InputPhone from '@atoms/InputPhone'
import InputCode from '@atoms/InputCode'
import Header from '@organisms/Header'
import UserCard from '@templates/UserCard'
import { useInput } from '@hooks/useInput'
import { useEffect, useMemo, useState } from 'react'
import { clearPhoneMask } from '@hooks/helpers'
import Loader from '@atoms/Loader'
// import cn from 'classnames'
import {
  useGetTariffsListQuery,
  useLazyGetPaymentLinkQuery,
} from '@services/user'
import { useInitialData } from '@hooks/useInitialData'

const AuthTemplate = () => {
  const { phone: defaultPhone } = useInitialData()
  const [stepTwo, setStepTwo] = useState(false)
  const [profileUser, setProfileUser] = useState<any>([])
  const [nextSms, setNextSms] = useState(false)
  const [checkoutPay, setCheckoutPay] = useState(0) //2 - 2фон 3 - 3фон
  const [backgroundClassState, setBackgroundClassState] = useState(
    `${styles[`background${checkoutPay}`]}`
  )
  const {
    bind: bindPhone,
    setValue,
    value: phone,
  } = useInput(defaultPhone || '')
  const [, setError] = useState(null)
  const { isLoading: isLoadingSub } = useGetTariffsListQuery('1')
  const [getLink, { data: link, isLoading: isLoadingLink }] =
    useLazyGetPaymentLinkQuery()

  const isLoading = useMemo(() => {
    return isLoadingSub || isLoadingLink
  }, [isLoadingSub, isLoadingLink])

  useEffect(() => {
    const currentAuth: any = localStorage.getItem('tokenUser')
    if (currentAuth && currentAuth.trim() !== '') {
      // console.log('check token', currentAuth)
      setStepTwo(true)
    }
  }, [])

  const submit = async () => {
    const data = {
      phone: clearPhoneMask(phone),
      is_subscribe: true,
    }
    // console.log(data)
    setStepTwo(true)
    setError(null)
    getLink(data)
  }

  useEffect(() => {
    if (!link) {
      return
    }
    if (typeof link === 'string') {
      const win: Window = window
      win.location = link
    } else if (!link.success) {
      setError(link.error)
    } else if (link.success) {
      setStepTwo(true)
      // if (window.innerWidth > 570) {
      //   setTimeLeft(30)
      // }
      // console.log('link.success')
    }
  }, [link])

  useEffect(() => {
    setValue(defaultPhone)
  }, [defaultPhone, setValue])

  useEffect(() => {
    if (
      typeof profileUser === 'object' &&
      profileUser !== null &&
      Object.keys(profileUser).length > 0
    ) {
      if (profileUser.subscribe_active) {
        setCheckoutPay(3)
      } else {
        setCheckoutPay(2)
      }
      setBackgroundClassState(`${styles[`background${checkoutPay}`]}`)
      // console.log('profile user', profileUser)
    }
  }, [backgroundClassState, checkoutPay, profileUser])

  if (nextSms) {
    setNextSms(false)
    submit()
  }

  return (
    <div className={`${styles.wrapper} ${backgroundClassState}`}>
      <Header />
      {checkoutPay === 2 || checkoutPay === 3 ? (
        <UserCard profileUser={profileUser} />
      ) : (
        <div className={styles.content}>
          <Card className={styles.form}>
            {isLoading && <Loader className={styles.loader} />}
            <h1 className={styles.title}>Вход</h1>
            {stepTwo && <p className={styles.inputCod}>Введите код из СМС</p>}
            <div className={styles.fieldInput}>
              {!stepTwo && (
                <InputPhone
                  required
                  {...bindPhone}
                  label="Номер телефона"
                  className={styles.phone}
                />
              )}
              {stepTwo && (
                <InputCode
                  phone={phone}
                  nextSms={nextSms}
                  setNextSms={setNextSms}
                  setProfileUser={setProfileUser}
                  setStepTwo={setStepTwo}
                />
              )}
            </div>
            {!stepTwo && (
              <Button className={styles.submit} onClick={submit}>
                Отправить код
              </Button>
            )}

            {!stepTwo && (
              <p className={styles.showWarning}>
                Авторизируясь, вы соглашаетесь с Условиями предоставления услуг
                и Политикой конфиденциальности
              </p>
            )}
          </Card>
        </div>
      )}
    </div>
  )
}

export default AuthTemplate
