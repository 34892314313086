import { FC, ReactNode } from 'react'
import styles from './styles.module.scss'
import cn from 'classnames'

type layout = {
  children: ReactNode
  isMain?: boolean
}

const Layout: FC<layout> = ({ children, isMain }) => {
  return (
    <div
      className={cn(styles.app, {
        [styles.isMain]: isMain,
      })}
    >
      <main className={styles.main}>{children}</main>
    </div>
  )
}

export default Layout
