import cn from 'classnames'
import styles from './styles.module.scss'
import { FC, SetStateAction, Dispatch, useState, useEffect } from 'react'
import Button from '@atoms/Button'
import { usePostUserCodeMutation, useGetDataUserQuery } from '@services/user'

interface Props {
  phone: string
  nextSms: boolean
  setNextSms: Dispatch<SetStateAction<boolean>>
  setProfileUser: Dispatch<SetStateAction<string[]>>
  setStepTwo: Dispatch<SetStateAction<boolean>>
}

const InputCode: FC<Props> = ({
  phone,
  nextSms,
  setNextSms,
  setProfileUser,
  setStepTwo,
}) => {
  const [code, setCode] = useState(['', '', '', ''])
  const [token, setToken] = useState('')
  const [falseCode, setFalseCode] = useState(false)
  const [timeLeft, setTimeLeft] = useState(300)
  const [postUserCode, { data: link }] = usePostUserCodeMutation()
  const {
    data: profile,
    error,
    // isLoading,
  } = useGetDataUserQuery(token, { skip: !token })

  useEffect(() => {
    const currentAuth: string | null = localStorage.getItem('tokenUser')
    if (currentAuth && currentAuth.trim() !== '') {
      // console.log('check token', currentAuth)
      setToken(JSON.parse(currentAuth))
    }
  }, [])

  const handleChange = (index: number, value: any) => {
    if (/^\d?$/.test(value)) {
      const newCode = [...code]
      newCode[index] = value
      setCode(newCode)
    }
  }
  // const profiles = {
  //   avatar: null,
  //   nickname: 'Testrest',
  //   phone: '79505560743',
  //   gender: 1,
  //   subscribe_active: true,
  //   subscribe_end_date: '2025-01-02 00:00:00',
  // }

  useEffect(() => {
    if (profile) {
      const data: any = profile
      setProfileUser(data)
      // console.log('data from profile', data)
    }
    if (error) {
      // console.log('data profile not get', error)
      localStorage.removeItem('tokenUser')
      setStepTwo(false)
    }
  }, [profile, setProfileUser, error, setStepTwo])

  const checkCode = async () => {
    const data = {
      phone: phone.replace(/[+\s()]/g, ''),
      code: code.join(''),
    }
    if (!link) {
      setFalseCode(true)
    }
    await postUserCode(data) // Call the mutation
    // console.log(data)
  }

  useEffect(() => {
    if (!link) {
      return
    }
    if (typeof link === 'string') {
      const win: Window = window
      win.location.href = link
    } else if (!link) {
      // console.log('!link.success', link)
    } else if (link.success) {
      // console.log('link.success', link.success, link.token)
      localStorage.setItem('tokenUser', JSON.stringify(link.token))
      setToken(link.token)
      setFalseCode(false)
    }
  }, [link])

  useEffect(() => {
    let timer: any = null
    if (timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1)
      }, 1000)
    } else {
      clearInterval(timer)
    }
    return () => clearInterval(timer)
  }, [timeLeft, nextSms])

  const submit = () => {
    setNextSms(true)
    setTimeLeft(300)
    setCode(['', '', '', ''])
    setFalseCode(false)
  }

  return (
    <>
      <div>
        {code.map((digit, index) => (
          <input
            key={index}
            type="number"
            value={digit}
            onChange={(e) => handleChange(index, e.target.value)}
            className={cn(styles.numberCode, {
              [styles.false]: falseCode,
            })}
            // onBlur={codeFocus} // Проверяем код при потере фокуса
          />
        ))}
      </div>
      <div className={styles.timeNewCode}>
        {timeLeft > 0 ? (
          `Запросить новый код можно через ${Math.floor(timeLeft / 60)
            .toString()
            .padStart(2, '0')}:${(timeLeft % 60)
            .toString()
            .padStart(2, '0')} сек`
        ) : (
          <button onClick={submit} className={styles.newCodeOrder}>
            Запросить новый код
          </button>
        )}
      </div>
      <Button className={styles.checkCode} onClick={checkCode}>
        Подтвердить
      </Button>
    </>
  )
}

export default InputCode
